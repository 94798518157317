<template>
    <div class="content">
        <div class="banner-image">
            <img src="@/assets/images/AboutUs_bg.png" alt="" width="100%">
            <div class="text">To become a trusted name and home care provider of choice in Asian Country, All families who only want the best home care through both our helpers and staff.</div>
        </div>
        <CommonTitle class="common_title" text="OUR CORE VALUES" />
        <div class="core_content">
            <div class="row">
                <div class="item">
                    <img class="bg_image" src="@/assets/images/value1.png" alt="" width="100%">
                    <div class="left">
                        <img src="@/assets/images/Integrity@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">Integrity</div>
                        <div class="text2">
                            We uphold the highest standards of integrity in all our actions. Our commitment to honesty and transparency ensures trust and reliability in every interaction with our clients, candidates, and partners.
                        </div>
                    </div>
                </div>
                <div class="item">
                    <img class="bg_image" src="@/assets/images/value2.png" alt="" width="110%">
                    <div class="left">
                        <img src="@/assets/images/Compassion@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">Compassion</div>
                        <div class="text2">
                            We approach every interaction with empathy and understanding. By showing genuine care and concern for the well-being of our clients and candidates, we build meaningful relationships and create a supportive environment.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mgT16">
                <div class="item">
                    <img class="bg_image" src="@/assets/images/value3.png" alt="" width="100%">
                    <div class="left">
                        <img src="@/assets/images/Accountability@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">Accountability</div>
                        <div class="text2">
                            We take responsibility for our actions and deliver on our promises. Our commitment to accountability ensures that we meet our obligations and uphold the trust placed in us by our clients and candidates.
                        </div>
                    </div>
                </div>
                <div class="item">
                    <img class="bg_image" src="@/assets/images/value4.png" alt="" width="110%">
                    <div class="left">
                        <img src="@/assets/images/CustomerCommitment@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">Customer Commitment</div>
                        <div class="text2">
                            Our clients and candidates are at the heart of everything we do. We are dedicated to understanding their needs and providing personalized solutions that exceed their expectations. Our unwavering commitment to customer satisfaction drives us to go above and beyond in every service we offer.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CommonTitle class="common_title2" text="CONTACT US" />
        <div class="map_content">
            <div class="flex-space">
                <div class="map">
                    <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.4971607902476!2d121.0253889!3d14.570723599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c9ae565fdc73%3A0x8a7f4c872af772f0!2zMTEzNCBBbnRpcG9sbywgTWFrYXRpLCAxMjEwIEthbGFraGFuZyBNYXluaWxhLCDoj7Llvovlrr4!5e0!3m2!1sen-us!2s!4v1693189148496!5m2!1sen-us!2s"
                    width="568"
                    height="260"
                    style="border:0; border-radius: 16px"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="info">
                    <p class="title">PHATES MANAGEMENT RESOURCES INC</p>
                    <div class="detail">
                        <img src="@/assets/images/location@2x.png" width="16px" style="vertical-align: super">
                        <span>1134 Antipolo Street, Barangay. Población, Makati City, Philippines 1210</span>
                    </div>
                    <div class="detail">
                        <img src="@/assets/images/phone@2x.png" width="16px">
                        <span>+63 9175207828</span>
                    </div>
                    <div class="detail">
                        <img src="@/assets/images/email@2x.png" width="16px" style="cursor: pointer;">
                        <a :href="'mailto:customerservice@supply-on.com'">
                            <span class="text text-link" style="display:inline-block; cursor: pointer; width: 280px">
                                customerservice@supply-on.com
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">@ Copyright PHATES MANAGEMENT RESOURCES INC</div>
        <div style="height: 32px"></div>
    </div>
</template>

<script>
    import { Vue, Component } from 'vue-property-decorator'
    import CommonTitle from './component/CommonTitle.vue'

    @Component({
        name: 'AboutUs',
        components: {
            CommonTitle
        }
    })
    export default class extends Vue {

    }
</script>

<style lang="scss" scoped>
.content {
    margin-top: 50px;
    ::v-deep .common_title {
        margin-top: 60px !important;
    }
    ::v-deep .common_title2 {
        margin-top: 60px !important;
    }
    .banner-image {
        position: relative;

        .text {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            white-space: break-word;
            text-align: center;
            width: 1160px;
            font-weight: 400;
            font-size: 20px;
            color: #222222;
            line-height: 27px;
        }
    }
    .core_content {
        margin: 0 140px;
        margin-top: 42px;
        .row {
            display: flex;
            justify-content: space-between;
        }
        .item {
            position: relative;
            width: 50%;
            padding: 32px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            margin-right: 16px;
            .bg_image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                z-index: -1;
            }
            .right {
                margin-left: 26px;
                .text1 {
                    line-height: 64px;
                    font-size: 20px;
                    font-weight: 800;
                    color: #01241E;
                }
                .text2 {
                    font-size: 15px;
                    color: #777777;
                    width: 85%;
                    line-height: 24px;
                }
            }
        }
    }
    .map_content{
        width: 1160px;
        margin: 40px auto 80px auto;
        .map{

        }
        .info{
            .title{
                font-size: 18px;
                font-weight: 900;
                color: #01241E;
                line-height: 21px;
                letter-spacing: 1px;
            }
            .detail{
                margin: 24px 0;
                img{
                    vertical-align: middle;
                    margin-right: 18px;
                }
                span{
                    width: 520px;
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 400;
                    color: #01241E;
                    line-height: 19px;
                    letter-spacing: 1px;
                }
            }
        }
        .fb{
            cursor: pointer;
            line-height: 36px;
            width: 130px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 18px;
            border: 1px solid #E7E7E7;
            font-size: 16px;
            color: #222222;
            letter-spacing: 1px;
            img{
                vertical-align: middle;
                margin: 0 10px;
            }
            span{
                vertical-align: middle;
            }
        }
    }
    .footer {
        text-align: center;
        margin-top: 79px;
        font-size: 16px;
        color: #777;
    }
}
</style>
